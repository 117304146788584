import React, { useEffect, useRef } from 'react';
import AOS from 'aos';

import styles from './Feature.module.css';

type FeatureAlignment = 'left' | 'right';

interface FeatureProp {
    title: string;
    description: string;
    alignment?: FeatureAlignment;
}

const Feature: React.FC<FeatureProp> = ({ title, description, alignment = 'left' }) => {
    const getFeatureAlignment = (): string => {
        switch (alignment) {
            case 'left':
                return styles.left;
            case 'right':
                return styles.right;
        }
    }

    const getAOSData = (): string => {
        switch (alignment) {
            case 'left':
                return "fade-right";
            case 'right':
                return "fade-left";
        }
    }

    useEffect(() => {
        AOS.init({
            duration: 600,
            once: false,
        });
    }, [])

    return (
        <div data-aos={getAOSData()} data-aos-delay="100" className={`${styles.core_bg} ${getFeatureAlignment()}`}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
        </div>
    )
}

export default Feature;