import React, { useRef } from 'react';
import styles from './ButtonType1.module.css';

type ButtonVarient = 'primary' | 'secondary' | 'caution' | 'danger';

interface ButtonProp {
    text: string;
    onClick?: () => void;
    varient?: ButtonVarient;
}

const ButtonType1: React.FC<ButtonProp> = ({ text, onClick, varient = 'primary' }) => {
    const getButtonClassName = (): string => {
        switch (varient) {
            case 'primary':
                return styles.primary;
            case 'secondary':
                return styles.secondary;
            case 'caution':
                return styles.caution;
            case 'danger':
                return styles.danger;
        }
    }
    return (
        <div className={`${getButtonClassName()} ${styles.universalButtonType1Style}`} onClick={onClick}>
            <p>{text}</p>
        </div>
    );
};

export default ButtonType1;
