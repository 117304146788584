import React, { useRef, useState } from 'react';
import styles from './Welcome.module.css';
import 'aos/dist/aos.css';
import { scrollToTarget } from '../utils/GlobalActions';
import SignupForm from '../components/Form_Signup';
import ButtonType1 from '../components/ButtonType1';
import Feature from '../components/Feature';
import { isWhiteSpaceLike } from 'typescript';

const Welcome: React.FC = () => {

    const featureSectionRef = useRef<HTMLDivElement>(null);
    const [showSignup, setShowSignup] = useState(false);
    const handleButtonClick = () => {
        setShowSignup(true);
    };
    const closeModal = () => {
        setShowSignup(false);
    }

    return (
        <div className={styles.pageContent}>
            <div className={styles.hero}>
                <div className={styles.heroPanel}>
                    <div className={styles.titlePanel}>
                    <img className={styles.imageCloudTop} alt="" src="/assets/image-Cloud-Top.png" />
                        <h2 className={styles.comingSoon}><strong>Coming Soon!</strong></h2>
                        <div>
                            <h1 className={styles.title}>
                                <span >Project</span>
                                <span style={{ color:"#0084ff" }}>Coelus</span>
                            </h1>
                        </div>
                        <h2 className={styles.subtitle}><i >See Beyond the Horizon</i></h2>
                    </div>
                    <ButtonType1 text="Learn More!" onClick={() => scrollToTarget(featureSectionRef)} varient="primary" />
                </div>
            </div>
            <div className={styles.features} ref={featureSectionRef}>
                <h2 style={{display: "none"}}>Features</h2>
                <Feature
                    title="Real-Time Project Monitoring"
                    description="Coelus will keep up with the day to day changes so you can invest your time where it matters most!"
                />
                <Feature
                    title="Flexible Project Configuration"
                    description="Coelus provides easy to use Project Configuration that gives you the power to define the metrics that best represent your project!"
                    alignment="right"
                />
                <Feature
                    title="Portfolio and Team Management"
                    description="Coelus manages your entire portfolio of projects giving you the ability to assign and manage teams, and monitor the most critical KPIs you define for each project!"
                />
            </div>
            <div className="hero">
                <div className={styles.heroPanel}>
                <img className={styles.imageCloudBottom} alt="" src="/assets/image-Cloud-Bottom.png" />
                    <div className={styles.titlePanel}>
                            <h1 className={`${styles.title} ${styles.titleBottom}`} style={{transform: "scale(.5)"}}>
                                <span >Project</span>
                                <span style={{ color: "#0084ff" }}>Coelus</span>
                            </h1>
                            <p className={styles.salutation}><strong>See you soon!</strong></p>
                            <ButtonType1 text="Join the Wait List!"/>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Welcome;